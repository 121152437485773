<template>
    <ul class="flashcard-list">
        <li v-for="(card, index) in cards" :key="index">
            <transition name="flip">
                <v-card v-if="!card.flipped && (card.active || !card.active && viewInactive)" v-bind:key="card.flipped" :class="getCardClass(card)">
                    
                    <span class="edit-card" v-on:click="toggleCard(card)"><v-icon>mdi-pencil</v-icon></span>
                    <v-row justify="center">
                    <v-icon v-if="card.icon == ''">mdi-truck</v-icon>
                    <v-img v-else class="text-align-center"
                        max-height="50"
                        max-width="50"
                        :src="card.icon"
                        ></v-img>
                    </v-row>
                    <v-card-title>{{card.name}}</v-card-title>
                    <v-card-subtitle>{{card.uniqueid}}</v-card-subtitle>
                    <v-list class="transparent">
                        <v-list-item>
                            <v-list-item-title>
                                Type
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                            {{card.devicetype.name}}
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                Phone
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{card.phone}}
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                Expiration Date
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{getDate(card)}}
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item :to="'/device/'+card.id+'/payments'" v-if="isAdmin">
                            <v-list-item-title>
                                Pagos
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>


                <v-card v-else-if="card.flipped && (card.active || !card.active && viewInactive)" :class="getCardClass(card)">
                    <span class="delete-card" @click="remove(card)"><v-icon>{{card.active?'mdi-delete':'mdi-delete-restore'}}</v-icon></span>
                    <span class="edit-card" v-on:click="toggleCard(card)"><v-icon>mdi-backup-restore</v-icon></span>
                    <v-select :items="iconList" v-model="card.icon" item-value="image" label="Icon">
                        <template v-slot:selection="{ item }">
                            <img :src="item.image" height="30px" />
                        </template>
                        <template v-slot:item="{ item }">
                            <img :src="item.image" height="30px" />
                        </template>
                    </v-select>
                    <v-card-title><v-text-field label="Name" v-model="card.name" /></v-card-title>
                    <v-card-subtitle><v-text-field label="Imei" v-model="card.uniqueid" :disabled="$store.state.user.roleid > 1" /></v-card-subtitle>
                    <v-list class="transparent">
                        <v-list-item>
                            <v-select 
                            :items="types" 
                            item-text="name"
                            item-value="id"
                            label="Types" 
                            v-model="card.devicetypeid">
                            </v-select>
                        </v-list-item>
                        <v-list-item>
                            <v-text-field label="Phone" v-model="card.phone" />
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>
                                Expiration Date
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{getDate(card)}}
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item v-if="isAdmin">
                          <v-list-item-title>
                            User Id
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-text-field label="Name" v-model="card.userid" />
                          </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                    <v-card-actions>
                        <base-btn class="success" @click="save(card)">Save</base-btn>
                        <base-btn color="error" @click="toggleCard(card)">Cancel</base-btn>
                    </v-card-actions>
                </v-card>
           
            </transition>
        </li>
    </ul>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';

export default {
    props:{
        cards: {
            type:Array
            ,default: function () { 
                return [];
            }
        }
        ,types: {
            type:Array
            ,default: () =>  []
        }
        ,iconList: {
            type:Array
            ,default: () =>  []
        }
        ,viewInactive:{
            type:Boolean
            ,default:true 
        }
    },
    created(){

    },
    data:function(){
      return{
        
        //,host:process.env.VUE_APP_API_BASE_URL
        //,localCards:this.cards
    }},
    computed:{
      ...mapGetters(['isAdmin']),
    },
    methods:{
        ...mapActions(['updateDevice','showSnackbar']),
        toggleCard: function(card) {
          card.flipped = !card.flipped;
          if(!card.flipped)
            this.$emit('reload');
        },
        save: function(card){
          //console.log(card);
          this.updateDevice(card).then((res)=>{
            //console.log(res);
            if(res.status == 200)
              this.showSnackbar({
                showing:true,
                message: `Updated device ${res.data.data.name}!`,
                color:'success'
              });
            
            this.toggleCard(card);
          }) .catch((err)=>{
                console.log(err);
                if(err.data.errors)
                this.showSnackbar({
                  showing:true,
                  message: err.data.errors[0],
                  color:'error'
                });
            });
        }
        ,remove(card){
          card.active = !card.active;
          this.updateDevice(card).then((res)=>{
            //console.log(res);
            if(res.status == 200){
              var status = card.active?'restored':'removed' 
              this.showSnackbar({
                showing:true,
                message: `Device ${res.data.data.name} ${status}!`,
                color:'success'
              });
            }
            
            this.toggleCard(card);
          }) .catch((err)=>{
                console.log(err);
                if(err.data.errors)
                  this.showSnackbar({
                    showing:true,
                    message: err.data.errors[0],
                    color:'error'
                  });
                card.active = !card.active;
            });
        }
        ,getCardClass(card){
          var classes = "card "+ (card.active?'':'inactive') 
              + (this.$func.isExpired(card.expirationDate)?' expired':'');
          
          return classes;
        }
        ,getDate(item){
            return this.$func.getDateString(item.expirationDate,'');
        },
    }
}
</script>

<style>
ul {
    padding-left: 0;
    display: flex;
    flex-flow: row wrap;
  }
  
  li {
    list-style-type: none;
    padding: 10px 10px;
    transition: all 0.3s ease;
  }
  .card {
    display: block;
/*    width: 150px;
    height: 175px;
    padding: 80px 50px; */
    padding-top: 40px;
    background-color: #98d6ff;
    border-radius: 7px;
    margin: 5px;
    text-align: center;
    line-height: 27px;
    cursor: pointer;
    position: relative;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    -webkit-box-shadow: 9px 10px 22px -8px rgba(209,193,209,.5);
    -moz-box-shadow: 9px 10px 22px -8px rgba(209,193,209,.5);
    box-shadow: 9px 10px 22px -8px rgba(209,193,209,.5);
    will-change: transform;
  }
  
  li:hover{
    transform: scale(1.1);
  }

  li .card.v-card{
    background-color: #98d6ff;
  }
  li .card.inactive{
    background-color: #a1a1a1;
  }
  li .card.expired{
    background-color: #f1c25c;
  }
  li .card.expired.inactive{
    background-color: #ffa500;
  }
  /*
  li:nth-child(-n+3) .card{
    background-color: #e65f51;
    }
  
  li:nth-child(2n+1) .card{
    background-color: #a17de9;
    }
  
  li:nth-child(4n) .card{
    background-color: #feca34;
    }
  
  li:nth-child(5n-2) .card{
    background-color: #51aae5;
    }
  
  li:nth-child(4n+4) .card{
    background-color: #feca34;
    }
  
  li:nth-child(-7n+7) .card{
    background-color: #e46055;
    }
  */
  .delete-card {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 15px;
    opacity: .4;
    transition: all 0.5s ease;
  }
  .edit-card {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 15px;
    opacity: .4;
    transition: all 0.5s ease;
  }
  
  .delete-card:hover, .error {
    opacity: 1;
    transform: rotate(360deg);
  }
  
  .flip-enter-active {
    transition: all 0.4s ease;
  }
  
  .flip-leave-active {
    display: none;
  }
  
  .flip-enter, .flip-leave {
    transform: rotateY(180deg);
    opacity: 0;
  
  }
  
  /* Form */
  .flashcard-form{
    position: relative;
  }
</style>